import { createClient, Session } from "@supabase/supabase-js";
import { Database } from "src/types/supabase";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseAnonKey)
  alert(
    "configuration error, missing REACT_APP_SUPABASE_URL or REACT_APP_SUPABASE_KEY"
  );
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);
export type SupabaseSession = Session;
export type Tables = Database['public']['Tables'];
export type Views = Database['public']['Views'];
export type Enums = Database['public']['Enums'];
