import { QueryClient, useQuery, UseQueryResult } from "@tanstack/react-query";
//import { supabase } from "./supabase";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000,
      refetchOnWindowFocus: false,
    },
  },
});

// throw this when expected to get a single record
class NotSingleError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, NotSingleError.prototype);
  }
}

// eg query = superbase.from('table').select()
const useSupabaseQuery = (
  name,
  query,
  options = {} as any
): UseQueryResult => {
  //return useQuery(prefixName("sb_", name), () => query, options);
  return useQuery(
    name,
    async () => {
      const { data, error } = await query;
      if (query.error) {
        throw new Error(error);
      }
      if (options?.single) {
        if (data.length !== 1) {
          throw new NotSingleError(
            `Single result of [${name}] query not found (result has ${data.length} items)`
          );
        }
        return data[0];
      }
      return data;
    },
    options
  );
};
export { useSupabaseQuery, queryClient };
