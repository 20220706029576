import { FunctionComponent, useEffect, ReactNode } from "react";
import { useAppState } from "src/state";

type Props = {
  children?: ReactNode;
};

interface KeyType {
  error?: string;
  error_description?: string;
  error_code?: string;
}

const throwError = (message: string, code: string): never => {
  const error = new Error(message);
  error.name = code;
  throw error;
};

const IdentityProvider: FunctionComponent<Props> = ({ children }) => {
  const [app, actions] = useAppState();

  useEffect(() => {
    actions.ensureSignIn(app.sso.session);
  }, [actions, app.sso.session]);

  if (!app.sso.session?.user) {
    if (window.location.hash) {
      const tmp = window.location.hash.substring(1).split("&");
      const key: KeyType = {}; // Explicitly define the type of key
      tmp.forEach((d) => {
        const v = d.split("=");
        if (v.length === 2) {
          key[v[0]] = decodeURIComponent(v[1].replace(/\+/g, " "));
        }
      });
      if (key.error) {
        throwError(key.error_description || "Unknown error", key.error || "Unknown code");
      }
    }

    return null; // loading
  }

  return <>{children}</>;
};

export default IdentityProvider;
