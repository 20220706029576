import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import * as Sentry from '@sentry/react';
import App from './App';
import { queryClient } from './lib/server';
import theme from './lib/theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// do not mud the water with dev errors
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://665913c487ee40f38d8ea7cec7e4810f@signal.proca.app/13",
    integrations: [Sentry.browserTracingIntegration()],
    release: "commit-" + process.env.REACT_APP_VERSION,
    // TODO We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  Sentry.setTag("codename", "wequo");
}

const appRoot = document.getElementById('root');
const root = createRoot(appRoot);

root.render(
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

